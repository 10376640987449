var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          ref: "formCreateClass",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Informações básicas" },
                  },
                  [
                    _c("b-card-text", [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("b-form-group", [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Título "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.title,
                                    expression: "item.title",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.item.title.$error,
                                },
                                attrs: { placeholder: "Título" },
                                domProps: { value: _vm.item.title },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "title",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("b-form-group", [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Título - "),
                                _c("small", [_vm._v("Exibido no contrato")]),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.title_contract,
                                    expression: "item.title_contract",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.item.title_contract.$error,
                                },
                                attrs: {
                                  placeholder: "Título exibido no contrato",
                                },
                                domProps: { value: _vm.item.title_contract },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "title_contract",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Curso Venda "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.course_sale.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsCourseSales,
                                    },
                                    on: { search: _vm.fetchCourseSale },
                                    model: {
                                      value: _vm.item.course_sale,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "course_sale", $$v)
                                      },
                                      expression: "item.course_sale",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Ficha Técnica "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.datasheet.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsDatasheets,
                                    },
                                    on: { search: _vm.fetchDatasheets },
                                    model: {
                                      value: _vm.item.datasheet,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "datasheet", $$v)
                                      },
                                      expression: "item.datasheet",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-b-1 mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(" Produto "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsProductsCategory,
                                    },
                                    on: { search: _vm.fetchProductsCategory },
                                    model: {
                                      value: _vm.item.product,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "product", $$v)
                                      },
                                      expression: "item.product",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-b-1 mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(" Produto Live "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsLiveProductsCategory,
                                    },
                                    on: {
                                      search: _vm.fetchLiveProductsCategory,
                                    },
                                    model: {
                                      value: _vm.item.product_live,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "product_live", $$v)
                                      },
                                      expression: "item.product_live",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-row mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-b-1 mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Escolaridade "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.scholarity.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsScholarities,
                                      searchable: false,
                                    },
                                    model: {
                                      value: _vm.item.scholarity,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "scholarity", $$v)
                                      },
                                      expression: "item.scholarity",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Tipo "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid": _vm.$v.item.type.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsCourseTypes,
                                      searchable: false,
                                    },
                                    model: {
                                      value: _vm.item.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "type", $$v)
                                      },
                                      expression: "item.type",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Painel de vendas "),
                                ]),
                                _c("v-select", {
                                  attrs: {
                                    searchable: false,
                                    label: "title",
                                    options: [
                                      { title: "Sim", code: 1 },
                                      { title: "Não", code: 0 },
                                    ],
                                  },
                                  model: {
                                    value: _vm.item.display_panel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "display_panel", $$v)
                                    },
                                    expression: "item.display_panel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Status "),
                                ]),
                                _c("v-select", {
                                  class: {
                                    "is-invalid": _vm.$v.item.status.$error,
                                  },
                                  attrs: {
                                    searchable: false,
                                    label: "title",
                                    options: [
                                      { title: "Ativo", code: 1 },
                                      { title: "Inativo", code: 0 },
                                    ],
                                  },
                                  model: {
                                    value: _vm.item.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "status", $$v)
                                    },
                                    expression: "item.status",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-tabs",
                  { attrs: { pills: "" } },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Parcelas")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-1 card-header-custom-actions",
                            attrs: { "header-tag": "header" },
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-11 col-8" },
                                        [
                                          _c("h4", { staticClass: "mb-0" }, [
                                            _c("i", {
                                              staticClass:
                                                "text-danger bi bi-record-circle",
                                            }),
                                            _vm._v(" Parcelas "),
                                          ]),
                                          _c("small", [
                                            _vm._v(
                                              "Cadastre as parcelas e selecione as formas de pagamento"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-1 col-4" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { 400: true },
                                                },
                                              ],
                                              staticClass: "btn-block",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click:
                                                  _vm.repeateAgainInstallments,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bi bi-plus",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm.item.installments.length
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex form-row-header-repeat",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-center d-none d-sm-block",
                                            staticStyle: { width: "7%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [_vm._v("Nº")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-center",
                                            staticStyle: { width: "16%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [_vm._v("Valor")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Parcelas x Valor",
                                                expression:
                                                  "'Parcelas x Valor'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass:
                                              "text-center d-none d-sm-block",
                                            staticStyle: { width: "20%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [_vm._v("Total")]
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.payment_forms,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "text-center",
                                                staticStyle: { width: "8%" },
                                                attrs: { id: index },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.top",
                                                        value: item.title,
                                                        expression:
                                                          "item.title",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "d-block",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      { attrs: { for: "" } },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi font-large-1",
                                                          class: item.icon,
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50px",
                                              visibility: "hidden",
                                            },
                                          },
                                          [_vm._v(" a ")]
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "b-form",
                                      {
                                        staticClass:
                                          "repeater-form installments-checkboxs repeater-form-custom",
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return _vm.repeateAgain.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.item.installments,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: "installment-key-" + index,
                                              staticClass:
                                                "d-flex justify-content: space-between; bg",
                                              staticStyle: {
                                                "align-items": "center",
                                              },
                                              attrs: {
                                                id: "installment-id-" + index,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "7%" },
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(index + 1) + "x"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: { width: "16%" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex items-center justify-content-center",
                                                    },
                                                    [
                                                      _c("money", {
                                                        staticClass:
                                                          "form-control text-center",
                                                        class: {
                                                          "is-invalid":
                                                            _vm.$v.item
                                                              .installments
                                                              .$each[index]
                                                              .value.$error,
                                                        },
                                                        attrs: {
                                                          placeholder: "0,00",
                                                        },
                                                        model: {
                                                          value: item.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      value: "Parcelas x Valor",
                                                      expression:
                                                        "'Parcelas x Valor'",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "text-center d-none d-sm-block",
                                                  staticStyle: { width: "20%" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("toCurrency")(
                                                          (index + 1) *
                                                            item.value
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "custom-control-primary course-plots",
                                                    attrs: { value: 1 },
                                                    model: {
                                                      value: item.money,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "money",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.money",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "custom-control-primary course-plots",
                                                    attrs: { value: 1 },
                                                    model: {
                                                      value: item.card_debit,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "card_debit",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.card_debit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "custom-control-primary course-plots",
                                                    attrs: { value: 1 },
                                                    model: {
                                                      value: item.card_credit,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "card_credit",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.card_credit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "custom-control-primary course-plots",
                                                    attrs: { value: 1 },
                                                    model: {
                                                      value: item.pix,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "pix",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.pix",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "custom-control-primary course-plots",
                                                    attrs: { value: 1 },
                                                    model: {
                                                      value: item.check,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "check",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.check",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "custom-control-primary course-plots",
                                                    attrs: { value: 1 },
                                                    model: {
                                                      value: item.billet,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "billet",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.billet",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "custom-control-primary course-plots",
                                                    attrs: { value: 1 },
                                                    model: {
                                                      value: item.letter,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "letter",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.letter",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "50px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mb-0 mt-1",
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.400",
                                                              value:
                                                                "rgba(234, 84, 85, 0.15)",
                                                              expression:
                                                                "'rgba(234, 84, 85, 0.15)'",
                                                              modifiers: {
                                                                400: true,
                                                              },
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            padding:
                                                              "8px !important",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            variant:
                                                              "outline-danger",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeAtinstallment(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-trash3",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass: "mb-0",
                                    class: {
                                      "text-danger":
                                        _vm.$v.item.installments.$dirty,
                                    },
                                  },
                                  [_vm._v(" Cadastre 1 ou mais parcelas ")]
                                ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Disciplinas")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-1 card-header-custom-actions",
                            attrs: { "header-tag": "header" },
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-11 col-8" },
                                        [
                                          _c("h4", { staticClass: "mb-0" }, [
                                            _c("i", {
                                              staticClass:
                                                "text-danger bi bi-record-circle",
                                            }),
                                            _vm._v(" Disciplinas "),
                                          ]),
                                          _c("small", [
                                            _vm._v(
                                              "Insira a carga horária para cada disciplina."
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-1 col-4" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { 400: true },
                                                },
                                              ],
                                              staticClass: "btn-block",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click:
                                                  _vm.repeateAgainDisciplines,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bi bi-plus",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm.item.disciplines.length
                              ? _c(
                                  "b-form",
                                  {
                                    staticClass:
                                      "repeater-form repeater-form-custom",
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.repeateAgainDisciplines.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-row form-row-header-repeat",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-7 col-8 text-left d-none d-sm-block",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [_vm._v("Disciplina")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Carga horária",
                                                expression: "'Carga horária'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass:
                                              "col-md-2 text-center d-none d-sm-block",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [_vm._v(" Carga Horária ")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-2 text-center d-none d-sm-block",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center justify-content",
                                              },
                                              [
                                                _vm._v(" DIPE "),
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      value:
                                                        "Disciplina inserida pós edital",
                                                      expression:
                                                        "\n                          'Disciplina inserida pós edital'\n                        ",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "bi bi-question-circle ml-1",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Carga horária",
                                                expression: "'Carga horária'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass:
                                              "col-md-1 text-center d-none d-sm-block",
                                          },
                                          [_c("label", { attrs: { for: "" } })]
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.item.disciplines,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: "discipline-key-" + index,
                                            staticClass:
                                              "form-row disciplines-course mb-1",
                                            attrs: {
                                              id: "discipline-id-" + index,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-7" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-0",
                                                  },
                                                  [
                                                    _c(
                                                      "v-select",
                                                      {
                                                        class: {
                                                          "is-invalid":
                                                            _vm.$v.item
                                                              .disciplines
                                                              .$each[index]
                                                              .discipline
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          label: "title",
                                                          "item-text": "title",
                                                          "item-value": "code",
                                                          placeholder:
                                                            "Digite o título",
                                                          options:
                                                            _vm.optionsDisciplines,
                                                        },
                                                        on: {
                                                          search:
                                                            _vm.fetchDisciplines,
                                                        },
                                                        model: {
                                                          value:
                                                            item.discipline,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "discipline",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.discipline",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "no-options",
                                                            },
                                                            slot: "no-options",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Nenhum registro encontrado "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-0",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value: [
                                                            "##:##",
                                                            "###:##",
                                                          ],
                                                          expression:
                                                            "['##:##', '###:##']",
                                                        },
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: item.workload,
                                                          expression:
                                                            "item.workload",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-workload",
                                                      class: {
                                                        "is-invalid":
                                                          _vm.$v.item
                                                            .disciplines.$each[
                                                            index
                                                          ].workload.$error,
                                                      },
                                                      attrs: {
                                                        placeholder: "00:00",
                                                        type: "tel",
                                                      },
                                                      domProps: {
                                                        value: item.workload,
                                                      },
                                                      on: {
                                                        input: [
                                                          function ($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              item,
                                                              "workload",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                          _vm.calcWorkload,
                                                        ],
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-0",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "d-flex",
                                                        staticStyle: {
                                                          "align-content":
                                                            "center",
                                                          "justify-content":
                                                            "center",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-checkbox",
                                                          {
                                                            staticClass:
                                                              "mr-1 custom-control-success float-left",
                                                            staticStyle: {
                                                              "margin-right":
                                                                "2px !important",
                                                            },
                                                            attrs: {
                                                              name: "check-button",
                                                              switch: "",
                                                              inline: "",
                                                              checked:
                                                                item.dipe,
                                                              value: 1,
                                                            },
                                                            model: {
                                                              value: item.dipe,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "dipe",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.dipe",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "switch-icon-left",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "3px",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-check",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "switch-icon-right",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "3px",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-x",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center align-items-center",
                                                            staticStyle: {
                                                              "column-gap":
                                                                "5px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                attrs: {
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "17",
                                                                  height: "17",
                                                                  color:
                                                                    "#F44336",
                                                                  fill: "none",
                                                                },
                                                              },
                                                              [
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z",
                                                                    stroke:
                                                                      "currentColor",
                                                                    "stroke-width":
                                                                      "1.5",
                                                                  },
                                                                }),
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M11.992 16H12.001",
                                                                    stroke:
                                                                      "currentColor",
                                                                    "stroke-width":
                                                                      "2",
                                                                    "stroke-linecap":
                                                                      "round",
                                                                    "stroke-linejoin":
                                                                      "round",
                                                                  },
                                                                }),
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M12 13L12 8.99997",
                                                                    stroke:
                                                                      "currentColor",
                                                                    "stroke-width":
                                                                      "1.5",
                                                                    "stroke-linecap":
                                                                      "round",
                                                                    "stroke-linejoin":
                                                                      "round",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(" DIPE "),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-1" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-0",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(234, 84, 85, 0.15)",
                                                            expression:
                                                              "'rgba(234, 84, 85, 0.15)'",
                                                            modifiers: {
                                                              400: true,
                                                            },
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          padding:
                                                            "11px 5px !important",
                                                        },
                                                        attrs: {
                                                          variant:
                                                            "outline-danger",
                                                          block: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeAtDiscipline(
                                                              index,
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash3",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-row mt-2" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-6 text-right",
                                          },
                                          [
                                            _vm._v(" Carga horária: "),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.totalWorkload) + "h"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-6 text-left" },
                                          [
                                            _vm._v(" Disciplinas: "),
                                            _c("strong", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.item.disciplines.length
                                                  )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass: "mb-0",
                                    class: {
                                      "text-danger":
                                        _vm.$v.item.disciplines.$dirty,
                                    },
                                  },
                                  [_vm._v(" Selecione 1 ou mais disciplinas ")]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Produtos EAD")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-1 card-header-custom-actions",
                            attrs: { "header-tag": "header" },
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-10 col-8" },
                                        [
                                          _c("h4", { staticClass: "mb-0" }, [
                                            _vm._v("Produtos EAD"),
                                          ]),
                                          _c("small", [
                                            _vm._v(
                                              "Selecione 1 ou mais produtos que serão concedidos na matrícula."
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-2 col-4" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { 400: true },
                                                },
                                              ],
                                              staticClass: "btn-block",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: _vm.repeateAgainProducts,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bi bi-plus",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm.item.products.length
                              ? _c(
                                  "b-form",
                                  {
                                    staticClass:
                                      "repeater-form repeater-form-custom",
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.repeateAgainProducts.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-row form-row-header-repeat",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-11 col-10 text-left d-none d-sm-block",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [_vm._v("Produto")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-1 text-center d-none d-sm-block",
                                          },
                                          [_c("label", { attrs: { for: "" } })]
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.item.products,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: "discipline-key-" + index,
                                            staticClass:
                                              "form-row disciplines-course mb-1",
                                            attrs: {
                                              id: "product-id-" + index,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-11" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-0",
                                                  },
                                                  [
                                                    _c(
                                                      "v-select",
                                                      {
                                                        attrs: {
                                                          label: "title",
                                                          "item-text": "title",
                                                          "item-value": "code",
                                                          placeholder:
                                                            "Digite o título",
                                                          options:
                                                            _vm.optionsProducts,
                                                        },
                                                        on: {
                                                          search:
                                                            _vm.fetchProducts,
                                                        },
                                                        model: {
                                                          value: item.product,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "product",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.product",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "no-options",
                                                            },
                                                            slot: "no-options",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Nenhum registro encontrado "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-1" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-0",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(234, 84, 85, 0.15)",
                                                            expression:
                                                              "'rgba(234, 84, 85, 0.15)'",
                                                            modifiers: {
                                                              400: true,
                                                            },
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          padding:
                                                            "11px 5px !important",
                                                        },
                                                        attrs: {
                                                          variant:
                                                            "outline-danger",
                                                          block: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeAtDiscipline(
                                                              index,
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash3",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("ButtonsActionsFooter", {
                  attrs: {
                    routerBack: "courses-list",
                    variant: "success",
                    submited: _vm.submited,
                    text: "Salvar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }