<template>
  <div>
    <b-form ref="formCreateClass" @submit.prevent="submitForm">
      <div class="form-row">
        <div class="col-md-12">
          <b-card header="Informações básicas" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>
                    <input
                      class="form-control"
                      v-model="item.title"
                      placeholder="Título"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título - <small>Exibido no contrato</small>
                    </label>
                    <input
                      class="form-control"
                      v-model="item.title_contract"
                      placeholder="Título exibido no contrato"
                      :class="{
                        'is-invalid': $v.item.title_contract.$error,
                      }"
                    />
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Curso Venda
                    </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.course_sale"
                      placeholder="Digite o título"
                      :options="optionsCourseSales"
                      @search="fetchCourseSale"
                      :class="{ 'is-invalid': $v.item.course_sale.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Ficha Técnica
                    </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.datasheet"
                      placeholder="Digite o título"
                      :options="optionsDatasheets"
                      @search="fetchDatasheets"
                      :class="{ 'is-invalid': $v.item.datasheet.$error }"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group class="mb-b-1 mb-0">
                    <label for=""> Produto </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.product"
                      placeholder="Digite o título"
                      :options="optionsProductsCategory"
                      @search="fetchProductsCategory"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group class="mb-b-1 mb-0">
                    <label for=""> Produto Live </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.product_live"
                      placeholder="Digite o título"
                      :options="optionsLiveProductsCategory"
                      @search="fetchLiveProductsCategory"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
              </div>

              <div class="form-row mt-2">
                <div class="col-md-4">
                  <b-form-group class="mb-b-1 mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Escolaridade
                    </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.scholarity"
                      placeholder="Digite o título"
                      :options="optionsScholarities"
                      :class="{ 'is-invalid': $v.item.scholarity.$error }"
                      :searchable="false"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-2">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Tipo
                    </label>
                    <v-select
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.type"
                      placeholder="Digite o título"
                      :options="optionsCourseTypes"
                      :class="{ 'is-invalid': $v.item.type.$error }"
                      :searchable="false"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-4">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Painel de vendas
                    </label>
                    <v-select
                      :searchable="false"
                      label="title"
                      v-model="item.display_panel"
                      :options="[
                        { title: 'Sim', code: 1 },
                        { title: 'Não', code: 0 },
                      ]"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-2">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Status
                    </label>
                    <v-select
                      :searchable="false"
                      label="title"
                      v-model="item.status"
                      :class="{ 'is-invalid': $v.item.status.$error }"
                      :options="[
                        { title: 'Ativo', code: 1 },
                        { title: 'Inativo', code: 0 },
                      ]"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-md-12">
          <b-tabs pills>
            <b-tab active>
              <template #title>
                <span class="d-none d-sm-inline">Parcelas</span>
              </template>
              <b-card
                class="mb-1 card-header-custom-actions"
                header-tag="header"
              >
                <template #header>
                  <div class="form-row">
                    <div class="col-md-11 col-8">
                      <h4 class="mb-0">
                        <i class="text-danger bi bi-record-circle"></i>
                        Parcelas
                      </h4>
                      <small
                        >Cadastre as parcelas e selecione as formas de
                        pagamento</small
                      >
                    </div>
                    <div class="col-md-1 col-4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeateAgainInstallments"
                        class="btn-block"
                      >
                        <i class="bi bi-plus"></i>
                      </b-button>
                    </div>
                  </div>
                </template>

                <div v-if="item.installments.length">
                  <div class="d-flex form-row-header-repeat">
                    <div
                      class="text-center d-none d-sm-block"
                      style="width: 7%"
                    >
                      <label for="">Nº</label>
                    </div>

                    <div class="text-center" style="width: 16%">
                      <label for="">Valor</label>
                    </div>

                    <div
                      style="width: 20%"
                      class="text-center d-none d-sm-block"
                      v-b-tooltip.hover.top="'Parcelas x Valor'"
                    >
                      <label for="">Total</label>
                    </div>

                    <div
                      v-for="(item, index) in payment_forms"
                      :key="index"
                      :id="index"
                      class="text-center"
                      style="width: 8%"
                    >
                      <div class="d-block" v-b-tooltip.hover.top="item.title">
                        <label for="">
                          <i class="bi font-large-1" :class="item.icon"></i>
                        </label>
                      </div>
                    </div>
                    <div class="" style="width: 50px; visibility: hidden">
                      a
                    </div>
                  </div>

                  <b-form
                    class="repeater-form installments-checkboxs repeater-form-custom"
                    @submit.prevent="repeateAgain"
                  >
                    <div
                      class="d-flex justify-content: space-between; bg"
                      style="align-items: center"
                      v-for="(item, index) in item.installments"
                      :id="`installment-id-${index}`"
                      :key="`installment-key-${index}`"
                    >
                      <div class="text-center" style="width: 7%">
                        <strong>{{ index + 1 }}x</strong>
                      </div>

                      <div style="width: 16%" class="">
                        <div class="d-flex items-center justify-content-center">
                          <money
                            v-model="item.value"
                            class="form-control text-center"
                            placeholder="0,00"
                            :class="{
                              'is-invalid':
                                $v.item.installments.$each[index].value.$error,
                            }"
                          >
                          </money>
                        </div>
                      </div>

                      <div
                        style="width: 20%"
                        class="text-center d-none d-sm-block"
                        v-b-tooltip.hover.top="'Parcelas x Valor'"
                      >
                        {{ ((index + 1) * item.value) | toCurrency }}
                      </div>

                      <div class="text-center" style="width: 8%">
                        <b-form-checkbox
                          :value="1"
                          v-model="item.money"
                          class="custom-control-primary course-plots"
                        >
                        </b-form-checkbox>
                      </div>

                      <div class="text-center" style="width: 8%">
                        <b-form-checkbox
                          :value="1"
                          v-model="item.card_debit"
                          class="custom-control-primary course-plots"
                        >
                        </b-form-checkbox>
                      </div>

                      <div class="text-center" style="width: 8%">
                        <b-form-checkbox
                          :value="1"
                          v-model="item.card_credit"
                          class="custom-control-primary course-plots"
                        >
                        </b-form-checkbox>
                      </div>

                      <div class="text-center" style="width: 8%">
                        <b-form-checkbox
                          :value="1"
                          v-model="item.pix"
                          class="custom-control-primary course-plots"
                        >
                        </b-form-checkbox>
                      </div>

                      <div class="text-center" style="width: 8%">
                        <b-form-checkbox
                          :value="1"
                          v-model="item.check"
                          class="custom-control-primary course-plots"
                        >
                        </b-form-checkbox>
                      </div>

                      <div class="text-center" style="width: 8%">
                        <b-form-checkbox
                          :value="1"
                          v-model="item.billet"
                          class="custom-control-primary course-plots"
                        >
                        </b-form-checkbox>
                      </div>

                      <div class="text-center" style="width: 8%">
                        <b-form-checkbox
                          :value="1"
                          v-model="item.letter"
                          class="custom-control-primary course-plots"
                        >
                        </b-form-checkbox>
                      </div>

                      <div class="" style="width: 50px">
                        <div class="mb-0 mt-1">
                          <b-button
                            style="padding: 8px !important; position: relative"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            block
                            @click="removeAtinstallment(index)"
                          >
                            <i class="bi bi-trash3"></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </div>
                <p
                  class="mb-0"
                  :class="{ 'text-danger': $v.item.installments.$dirty }"
                  v-else
                >
                  Cadastre 1 ou mais parcelas
                </p>
              </b-card>
            </b-tab>

            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">Disciplinas</span>
              </template>

              <b-card
                class="mb-1 card-header-custom-actions"
                header-tag="header"
              >
                <template #header>
                  <div class="form-row">
                    <div class="col-md-11 col-8">
                      <h4 class="mb-0">
                        <i class="text-danger bi bi-record-circle"></i>
                        Disciplinas
                      </h4>
                      <small
                        >Insira a carga horária para cada disciplina.</small
                      >
                    </div>
                    <div class="col-md-1 col-4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeateAgainDisciplines"
                        class="btn-block"
                      >
                        <i class="bi bi-plus"></i>
                      </b-button>
                    </div>
                  </div>
                </template>

                <b-form
                  v-if="item.disciplines.length"
                  class="repeater-form repeater-form-custom"
                  @submit.prevent="repeateAgainDisciplines"
                >
                  <div class="form-row form-row-header-repeat">
                    <div class="col-md-7 col-8 text-left d-none d-sm-block">
                      <label for="">Disciplina</label>
                    </div>

                    <div
                      class="col-md-2 text-center d-none d-sm-block"
                      v-b-tooltip.hover.top="'Carga horária'"
                    >
                      <label for=""> Carga Horária </label>
                    </div>

                    <div class="col-md-2 text-center d-none d-sm-block">
                      <label class="d-flex align-items-center justify-content">
                        DIPE
                        <i
                          class="bi bi-question-circle ml-1"
                          v-b-tooltip.hover.top="
                            'Disciplina inserida pós edital'
                          "
                        ></i>
                      </label>
                    </div>

                    <div
                      class="col-md-1 text-center d-none d-sm-block"
                      v-b-tooltip.hover.top="'Carga horária'"
                    >
                      <label for=""> </label>
                    </div>
                  </div>

                  <div
                    class="form-row disciplines-course mb-1"
                    v-for="(item, index) in item.disciplines"
                    :id="`discipline-id-${index}`"
                    :key="`discipline-key-${index}`"
                  >
                    <div class="col-md-7">
                      <div class="form-group mb-0">
                        <v-select
                          label="title"
                          v-model="item.discipline"
                          item-text="title"
                          item-value="code"
                          placeholder="Digite o título"
                          :options="optionsDisciplines"
                          @search="fetchDisciplines"
                          :class="{
                            'is-invalid':
                              $v.item.disciplines.$each[index].discipline
                                .$error,
                          }"
                        >
                          <span slot="no-options">
                            Nenhum registro encontrado
                          </span>
                        </v-select>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group mb-0">
                        <input
                          v-mask="['##:##', '###:##']"
                          placeholder="00:00"
                          type="tel"
                          @input="calcWorkload"
                          class="form-control form-control-workload"
                          v-model="item.workload"
                          :class="{
                            'is-invalid':
                              $v.item.disciplines.$each[index].workload.$error,
                          }"
                        />
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group mb-0">
                        <div
                          class="d-flex"
                          style="
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <b-form-checkbox
                            style="margin-right: 2px !important"
                            class="mr-1 custom-control-success float-left"
                            name="check-button"
                            switch
                            inline
                            :checked="item.dipe"
                            v-model="item.dipe"
                            :value="1"
                          >
                            <span
                              class="switch-icon-left"
                              style="margin-top: 3px"
                            >
                              <i class="bi bi-check"></i>
                            </span>
                            <span
                              class="switch-icon-right"
                              style="margin-top: 3px"
                            >
                              <i class="bi bi-x"></i>
                            </span>
                          </b-form-checkbox>
                          <span
                            class="d-flex justify-content-center align-items-center"
                            style="column-gap: 5px"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              color="#F44336"
                              fill="none"
                            >
                              <path
                                d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z"
                                stroke="currentColor"
                                stroke-width="1.5"
                              />
                              <path
                                d="M11.992 16H12.001"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 13L12 8.99997"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span> DIPE </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1">
                      <div class="form-group mb-0">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          block
                          style="padding: 11px 5px !important"
                          @click="removeAtDiscipline(index, item)"
                        >
                          <i class="bi bi-trash3"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <div class="form-row mt-2">
                    <div class="col-md-6 text-right">
                      Carga horária: <strong>{{ totalWorkload }}h</strong>
                    </div>

                    <div class="col-md-6 text-left">
                      Disciplinas:
                      <strong> {{ item.disciplines.length }}</strong>
                    </div>
                  </div>
                </b-form>
                <p
                  class="mb-0"
                  :class="{ 'text-danger': $v.item.disciplines.$dirty }"
                  v-else
                >
                  Selecione 1 ou mais disciplinas
                </p>
              </b-card>
            </b-tab>

            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">Produtos EAD</span>
              </template>
              <b-card
                class="mb-1 card-header-custom-actions"
                header-tag="header"
              >
                <template #header>
                  <div class="form-row">
                    <div class="col-md-10 col-8">
                      <h4 class="mb-0">Produtos EAD</h4>
                      <small
                        >Selecione 1 ou mais produtos que serão concedidos na
                        matrícula.</small
                      >
                    </div>
                    <div class="col-md-2 col-4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeateAgainProducts"
                        class="btn-block"
                      >
                        <i class="bi bi-plus"></i>
                      </b-button>
                    </div>
                  </div>
                </template>
                <b-form
                  v-if="item.products.length"
                  class="repeater-form repeater-form-custom"
                  @submit.prevent="repeateAgainProducts"
                >
                  <div class="form-row form-row-header-repeat">
                    <div class="col-md-11 col-10 text-left d-none d-sm-block">
                      <label for="">Produto</label>
                    </div>

                    <div class="col-md-1 text-center d-none d-sm-block">
                      <label for=""> </label>
                    </div>
                  </div>

                  <div
                    class="form-row disciplines-course mb-1"
                    v-for="(item, index) in item.products"
                    :id="`product-id-${index}`"
                    :key="`discipline-key-${index}`"
                  >
                    <div class="col-md-11">
                      <div class="form-group mb-0">
                        <v-select
                          label="title"
                          v-model="item.product"
                          item-text="title"
                          item-value="code"
                          placeholder="Digite o título"
                          :options="optionsProducts"
                          @search="fetchProducts"
                        >
                          <span slot="no-options">
                            Nenhum registro encontrado
                          </span>
                        </v-select>
                      </div>
                    </div>

                    <div class="col-md-1">
                      <div class="form-group mb-0">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          block
                          style="padding: 11px 5px !important"
                          @click="removeAtDiscipline(index, item)"
                        >
                          <i class="bi bi-trash3"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-form>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="form-row justify-content-end">
        <div class="col-md-4">
          <ButtonsActionsFooter
            routerBack="courses-list"
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  BCardHeader,
  VBTooltip,
  BFormCheckbox,
  VBToggle,
  BButton,
  BModal,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BTooltip,
  BTab,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, minLength } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { Money } from "v-money";

export default {
  components: {
    ButtonsActionsFooter,
    BSpinner,
    BModal,
    Money,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardText,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BTooltip,
    VBTooltip,
    BTab,
    BTabs,
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      nextInstallmentId: 2,
      nextDisciplineId: 2,
      nextProuctId: 2,
      submited: false,
      payment_forms: [
        {
          code: "dinheiro",
          icon: "bi-cash-stack",
          title: "Dinheiro",
        },
        {
          code: "debito",
          icon: "bi-credit-card-2-front",
          title: "Débito",
        },
        {
          code: "credito",
          icon: "bi-credit-card-2-back",
          title: "Cartão de Crédito",
        },
        {
          code: "pix",
          icon: "bi-qr-code-scan",
          title: "PIX",
        },
        {
          code: "cheque",
          icon: "bi-card-text",
          title: "Cheque",
        },
        {
          code: "letter",
          icon: "bi-file-earmark-medical",
          title: "Carta de Crédito",
        },
        {
          code: "billet",
          icon: "bi-upc-scan",
          title: "Boleto",
        },
      ],
      item: {
        title: "",
        title_contract: "",
        scholarity: "",
        type: "",
        course_sale: "",
        datasheet: "",
        status: "",
        product_live: "",
        product: "",
        installments: [],
        disciplines: [],
        products: [],
        display_panel: { title: "Sim", code: 1 },
      },
      installments: {
        installment: 1,
        value: "",
        total: "",
        card_debit: false,
        card_credit: false,
        money: false,
        check: false,
      },
      optionsScholarities: [],
      optionsCourseSales: [],
      optionsCourseTypes: [],
      optionsDatasheets: [],
      optionsDisciplines: [],
      optionsTeachers: [],
      optionsProducts: [],
      optionsProductsCategory: [],
      optionsLiveProductsCategory: [],
      totalWorkload: 0,
      nextInstallmentId: 1,
    };
  },
  validations: {
    item: {
      title: {
        required,
        minLength: minLength(5),
      },
      title_contract: {
        required,
        minLength: minLength(5),
      },
      scholarity: {
        required,
      },
      type: {
        required,
      },
      course_sale: {
        required,
      },
      datasheet: {
        required,
      },
      status: {
        required,
      },
      installments: {
        $each: {
          value: {
            required,
          },
        },
      },
      disciplines: {
        $each: {
          discipline: {
            required,
          },
          workload: {
            required,
          },
        },
      },
    },
  },
  methods: {
    async fetchProductsCategory(term) {
      this.optionsProductsCategory = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [1],
          })
          .then((res) => {
            this.optionsProductsCategory = res.data;
          });
      }
    },
    async fetchLiveProductsCategory(term) {
      this.optionsLiveProductsCategory = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [3],
          })
          .then((res) => {
            this.optionsLiveProductsCategory = res.data;
          });
      }
    },
    converterParaMinutos(hora) {
      const [horas, minutos] = hora.split(":").map(Number);
      return horas * 60 + minutos;
    },
    converterParaHorario(minutos) {
      const horas = Math.floor(minutos / 60);
      const minutosRestantes = minutos % 60;
      return `${String(horas).padStart(2, "0")}:${String(
        minutosRestantes
      ).padStart(2, "0")}`;
    },
    async calcWorkload() {
      let workload = 0;
      this.item.disciplines.forEach((element) => {
        workload += Number(element.workload);
      });

      const totalMinutos = this.item.disciplines.reduce((total, discipline) => {
        return total + this.converterParaMinutos(discipline.workload);
      }, 0);

      this.totalWorkload = this.converterParaHorario(totalMinutos);
    },
    async fetchProducts(term) {
      if (term.length > 2) {
        this.optionsProducts = await this.$store.dispatch("Product/search", {
          term: term,
        });
      }
    },
    async getData() {
      await this.$store
        .dispatch("Course/fetchShow", this.$route.params.uuid)
        .then((data) => {
          this.item = data;
          this.calcWorkload();
        });
    },
    async disciplineSelected(discipline) {
      this.optionsDisciplines = [];
      this.optionsTeachers = [];
      this.optionsTeachers = await this.$store.dispatch(
        "Teacher/getByTagsSubjects",
        { disciplineUuid: discipline.code }
      );
    },
    async fetchDisciplines(term) {
      this.optionsDisciplines = [];
      if (term.length > 2) {
        this.optionsDisciplines = await this.$store.dispatch(
          "Discipline/search",
          { term: term, ead: 0 }
        );
      }
    },
    async fetchCourseSale(term) {
      this.optionsCourseSales = await this.$store.dispatch(
        "CourseSale/forSelect",
        term
      );
    },
    repeateAgainDisciplines() {
      this.nextDisciplineId = this.nextDisciplineId + 1;

      this.item.disciplines.push({
        id: this.nextDisciplineId,
        ...this.disciplines,
        installment: this.item.disciplines.length + 1,
      });
    },
    repeateAgainProducts() {
      this.nextProuctId = this.nextProuctId + 1;

      this.item.products.push({
        id: this.nextProuctId,
        ...this.products,
      });
    },
    repeateAgainInstallments() {
      this.nextInstallmentId = this.nextInstallmentId + 1;

      this.item.installments.push({
        id: this.nextInstallmentId,
        ...this.installments,
        installment: this.item.installments.length + 1,
      });
    },
    async fetchDatasheets(term) {
      if (term.length > 2) {
        this.optionsDatasheets = await this.$store.dispatch(
          "Datasheet/search",
          term
        );
      }
    },
    removeAtDiscipline(index, element) {
      if (this.item.disciplines.length < 2) {
        this.notify(
          "Adicione pelo menos 1 disciplina",
          "BellIcon",
          "warning",
          ""
        );
        return false;
      }

      if (element.workload_uuid) {
        this.$swal({
          title: "Tem certeza?",
          text: "A disciplina será desvinculada deste curso.",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-light ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("Course/fetchDeleteDiscipline", {
                uuid: this.item.uuid,
                discipline_uuid: element.discipline.code,
              })
              .then(() => {
                this.getData();
                this.item.disciplines.splice(index, 1);
                this.notifyDefault("success");
              });
          }
        });
      } else {
        this.item.disciplines.splice(index, 1);
      }
    },

    removeAtinstallment(index) {
      this.item.installments.splice(index, 1);
    },
    submitForm() {
      this.$v.item.$touch();

      if (!this.$v.item.$error) {
        const dataForm = {
          ...this.item,
        };

        dataForm.scholarity = dataForm.scholarity.code;

        dataForm.type = dataForm.type.code;
        dataForm.course_sale = dataForm.course_sale.code;
        dataForm.datasheet = dataForm.datasheet.code;
        dataForm.product = dataForm.product ? dataForm.product.code : null;
        dataForm.product_live = dataForm.product_live
          ? dataForm.product_live.code
          : null;
        dataForm.status = dataForm.status.code;

        dataForm.products = dataForm.products.length
          ? JSON.stringify(dataForm.products)
          : [];
        dataForm.installments = dataForm.installments.length
          ? JSON.stringify(dataForm.installments)
          : [];
        dataForm.disciplines = dataForm.disciplines.length
          ? JSON.stringify(dataForm.disciplines)
          : [];
        dataForm.display_panel = dataForm.display_panel.code;

        this.submited = true;

        this.$store
          .dispatch("Course/fetchStore", dataForm)
          .then(() => {
            this.notifyDefault("success");
            this.$v.item.$reset();
            this.$router.push({ name: "courses-list" });
          })
          .catch(() => {
            this.submited = true;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async mounted() {
    this.optionsScholarities = await this.$store.dispatch(
      "Scholarity/allToSelect"
    );

    this.optionsCourseTypes = await this.$store.dispatch(
      "Course/forSelectTypes"
    );

    this.repeateAgainDisciplines();
    this.repeateAgainInstallments();
  },
};
</script>

<style scoped lang="scss">
.installments-checkboxs {
  .custom-checkbox {
    padding: 0px;
    text-align: center;
    display: block;
    margin: 0px 25px;
    padding: 0px !important;
    label {
      margin: 0px;
    }
  }
}
.disciplines-course {
  .vs__selected {
    font-size: 0.9rem !important;
  }
}
</style>
